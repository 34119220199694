module.exports = {
  isChosen: 'Chosen',
  areYouSure: 'Are you sure?',

  table: {
    service: 'Service',
    dateAndHour: 'Date and hour',
    patient: 'Patient',
    phone: 'Phone number',
    email: 'E-mail',
    therapist: 'Therapist',
    requestDate: 'Request Date',
    result: 'Result',
    empty: 'Reservations not found',
    actions: 'Actions'
  },

  actions: {
    delete: 'Delete',
    execute: 'Execute'
  },

  options: {
    confirm: 'Confirm the meeting',
    reject: 'Reject the meeting',
    delete: 'Delete the meeting'
  },

  tooltips: {
    clickToOpenOptions: 'Click to open options'
  },

  filters: {
    therapists: 'Therapists',
    services: 'Services',
    patients: 'Patients'
  },

  confirmation: {
    rejection: 'Are you sure you want to reject reservation(-s)?',
    deleting: 'Are you sure you want to delete reservation(-s)?'
  }
}
