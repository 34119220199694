const { getEmptyTableMessage } = require('./utils')

module.exports = {
  title: 'Practice income',
  income: 'Profits',
  expenses: 'Expenses',
  save: 'Save',
  changeMeetingsPaymentStatus: 'Change meetings payment status',
  paymentMethod: 'Payment method',
  paymentDate: 'Payment date',
  paymentStatus: 'Payment status',
  markTheFollowingMeeting: 'The following meetings will be changed',
  moreEntries: 'more entries',
  patient: 'patient',
  visibleOnlyForAdminOrFinanse:
    'This view is visible only for admin and financial role',
  actions: {
    delete: 'Delete expenses',
    markAsPaid: 'Mark services as paid'
  },
  table: {
    all: '@:employees.all',
    generatePdfReport: 'Generate pdf report',
    generateCsvReport: 'Generate csv report',
    generateExcelReport: 'Generate excel report',
    successfullyDeleted: 'Successfully deleted!',
    empty: getEmptyTableMessage('records', 'Add'),
    selected: 'selected',
    clear: 'Clear',
    total: 'TOTAL in selected period',
    pageTotal: 'TOTAL on page',
    apply: 'Apply',
    deleteSelected: 'delete selected',
    serviceName: 'Service Name',
    addExpense: 'Add expense',
    expensesName: 'Name',
    date: 'Date',
    addedBy: 'Added By',
    note: 'Note',
    repeat: 'Repeats',
    addIncome: 'Add income',
    timeDate: 'Time & Date',
    patient: 'Patient',
    therapist: 'Therapist',
    amount: 'Price',
    amountPaid: 'Paid',
    status: 'Status',
    week1: '1 week',
    week2: '2 week',
    month1: '1 month',
    payment: 'Payment',
    paid: 'Paid',
    unpaid: 'Unpaid',
    paymentNotRequired: 'Not required',
    confirmDeletion: 'Confirm deletion',
    confirmCaption: 'Are you sure you want to delete this expense?',
    confirmCaptionIncome: 'Are you sure you want to delete this income?',
    currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords}',
    changeMeetingsPaymentStatus: 'Change meetings payment status',
    filters: {
      name: 'Service picker',
      expensesName: 'Expense picker',
      addedBy: 'Added by picker',
      time: 'Time picker',
      patient: 'Patient picker',
      therapist: 'Therapist picker',
      amount: 'Price picker',
      status: 'Status picker',
      paymentMethod: 'Payment method picker'
    },
    payments: {
      paid: '@:sessions.payments.paid',
      paymentNotRequired: '@:sessions.payments.paymentNotRequired',
      awaitingPayment: '@:sessions.payments.awaitingPayment',
      other: '@:sessions.payments.other',
      PRZELEWY24: '@:sessions.payments.przelewy24'
    },
    paymentDate: 'Payment date'
  },
  expense: {
    addNewExpense: 'Add new expense',
    addNewIncome: 'Add new income',
    editIncome: 'Edit income',
    editExpense: 'Edit expense',
    expenseName: 'Expense name',
    serviceName: 'Service name',
    amount: 'Price',
    expenseDate: 'Expense date',
    incomeDate: 'Income date',
    paymentDate: 'Payment date',
    paid: 'Paid',
    paymentMethod: 'Payment method',
    patientSelect: 'Select patient',
    therapistSelect: 'Select therapist',
    statusSelect: 'Select status',
    payment: 'Payment',
    repeatCaption1: 'This expense',
    repeatCaption2: 'is a single expense',
    repeatCaption3: 'does repeat',
    expenseRepeatsEvery: 'Expense repeats every',
    week1: '@:income.table.week1',
    week2: '@:income.table.week2',
    month1: '@:income.table.month1',
    note: 'Note',
    save: 'Save',
    successfullyAdded: 'Successfully added!'
  }
}
